<template>
  <dialogComp title="开发票" :isShow="isShow" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="120px"
    >
      <ul class="info">
        <li>商家公司：{{billInfo.companyName || '-'}}</li>
        <li>纳税人识别号：{{billInfo.taxpayerNum || '-'}}</li>
        <li>地址/电话：{{billInfo.info || '-'}}</li>
        <li>开户银行名称：{{billInfo.bankName || '-'}}</li>
        <li>开户银行账号：{{billInfo.bankNum || '-'}}</li>
        <li>备注：{{billInfo.remark || '-'}}</li>
      </ul>
      <el-form-item label="申请开票金额:">
        {{info.price}}
      </el-form-item>
      <!-- <el-form-item label="申请开票金额:" prop="price">
          <el-input type="number" size="small" placeholder="请输入申请开票金额" v-model="formData.price" @blur="priceBlur"></el-input>
        </el-form-item> -->
        <inputFormComp
          label="发票代码:"
          prop="code"
          inputType="number"
          :hval="formData.code"
          @blur="codeBlur"
          placeholder="请输入发票代码"
        />
      <el-form-item label="开票日期:" prop="billingDate">
        <el-date-picker
          v-model="formData.billingDate"
          type="date"
          placeholder="请选择开票日期"
          size="small"
          style="width:100%"
        >
        </el-date-picker>
        <span>注：实际开票日期</span>
      </el-form-item>
      <inputFormComp
        label="发票备注:"
        prop="remark"
        inputType="textarea"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入发票备注"
      />
    </el-form>
  </dialogComp>
</template>
<script>
import { storeMakeBill, supplierBillInfo } from '@/api'
import { formatDate } from '@/utils/common'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      billInfo: {},
      formData: {
        code: '',
        billingDate: '',
        remark: ''
      },
      rules: {
        code: [
          { required: true, message: '请输入单价', trigger: 'blur' }
        ],
        billingDate: [
          { required: true, message: '请选择开票日期', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.getBillDetail()
        this.formData.remark = this.info.remark
      }
    }
  },
  methods: {
    getBillDetail () {
      supplierBillInfo({ storeId: this.info.storeId }).then(res => {
        if (res.data) {
          this.billInfo = res.data
        }
      })
    },
    codeBlur (val) {
      this.formData.code = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const { id } = this.info
          const { billingDate, code, remark } = this.formData
          const params = {
            id,
            code,
            remark,
            billingDate: formatDate(billingDate),
            status: 2
          }
          storeMakeBill(params).then(_ => {
            this.$message.success('开票成功')
            this.close()
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .info {
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    padding-bottom: 10px;
    li {
      line-height: 30px;
    }
  }
</style>
