<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <selectComp
              prop="status"
              placeholder="发票状态"
              :data="statusList"
              :hval="status"
              @change="statusChange"
            />
            <merchant :hval="storeName" @storeNameChange="storeNameChange" />
            <inputComp :hval="code" @blur="codeBlur" placeholder="发票代码" />
            <dateComp
              :hval="applyTime"
              startPlaceholder="申请开始日期"
              endPlaceholder="申请结束日期"
              @change="applyTimeChange"
            />
             <dateComp
              :hval="billTime"
              startPlaceholder="开票开始日期"
              endPlaceholder="开票结束日期"
              @change="billTimeChange"
            />
            <btnGroup @search="search" @reset="reset" />
            <!-- <btnComp @click="exportData">导出列表</btnComp> -->
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          v-loading="loading"
        >
          <el-table-column slot="createdTime" label="申请日期">
            <template v-slot="scope">
              {{scope.row.createdTime.slice(0,11)}}
            </template>
          </el-table-column>
          <el-table-column slot="operate" label="操作" fixed="right">
            <template v-slot="scope">
              <btnComp v-permission="'开票'" v-if="scope.row.status === 1" btnType="text" @click="markBill(scope.row)">开票</btnComp>
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
    <!-- 申请开票 -->
     <makeBill :isShow="markBillShow" @close="close" :info="info" />
  </div>
</template>
<script>
import makeBill from './makeBill'
import merchant from '@/views/components/merchant'
import { storeBillList } from '@/api'
import { formatDate } from '@/utils/common'
export default {
  components: { makeBill, merchant },
  data () {
    return {
      loading: false,
      markBillShow: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      info: {},
      status: '',
      storeId: '',
      storeName: '',
      code: '',
      applyStartTime: '',
      applyEndTime: '',
      billingStartTime: '',
      billingEndTime: '',
      applyTime: [],
      billTime: [],
      statusList: [
        { label: '申请开票', value: 1 },
        { label: '已开票', value: 2 }
      ],
      tableData: [],
      theadName: [
        { slot: 'operate' },
        { prop: 'identifier', label: 'ID' },
        { prop: 'statusName', label: '发票状态' },
        { prop: 'invoice', label: '发票抬头' },
        { prop: 'code', label: '发票代码' },
        { prop: 'price', label: '开票金额' },
        { prop: 'remark', label: '备注' },
        { slot: 'createdTime' },
        { prop: 'billingDate', label: '开票日期' },
        { prop: 'applicant', label: '申请人' },
        { prop: 'drawer', label: '开票人' }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      this.params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        status: this.status,
        storeId: this.storeId,
        code: this.code,
        applyStartTime: this.applyStartTime,
        applyEndTime: this.applyEndTime,
        billingStartTime: this.billingStartTime,
        billingEndTime: this.billingEndTime
      }
      this.loading = true
      storeBillList(this.params).then(res => {
        this.loading = false
        this.tableData = res.data.list
        this.total = res.data.totalCount
      })
    },
    // 导出
    /*   exportData () {
      Export(this.params)
      this.$message({
        message: '已经发起导出任务，请关注个人中心导出任务列表页面，任务完成后可以下载导出文档',
        type: 'warning',
        duration: 5000
      })
    }, */
    // 处理时间
    handleDate (date) {
      return date ? formatDate(date, 'YYYY-MM-DD HH:mm:ss') : '-'
    },
    storeNameChange (val) {
      this.storeId = val.partnerId
      this.storeName = val.partnerName
    },
    codeBlur (val) {
      this.code = val
    },
    statusChange (val) {
      this.status = val
    },
    applyTimeChange (applyStartTime, applyEndTime) {
      this.applyStartTime = applyStartTime
      this.applyEndTime = applyEndTime
    },
    billTimeChange (billingStartTime, billingEndTime) {
      this.billingStartTime = billingStartTime
      this.billingEndTime = billingEndTime
    },
    // 明细
    detail (row) {
      this.$router.push({
        name: 'stroe-pay-detail',
        query: {
          id: row.id
        }
      })
    },
    // 申请开票
    markBill (info) {
      this.info = info
      this.markBillShow = true
    },
    close () {
      this.markBillShow = false
      this.getList()
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.status = ''
      this.code = ''
      this.storeId = ''
      this.storeName = ''
      this.applyStartTime = ''
      this.applyEndTime = ''
      this.billingStartTime = ''
      this.billingEndTime = ''
      this.applyTime = []
      this.billTime = []
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
